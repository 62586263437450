





















import { Vue, Component } from "vue-property-decorator";

export interface ContentSectionItem {
  name: string;
  routeName: string;
  routeParams?: any;
}

@Component
export default class StoreFrontContentDashboard extends Vue {
  get storeFrontId() {
    return this.$route.params.id;
  }

  get contentSections(): ContentSectionItem[] {
    return [
      { name: 'BannerGroups', routeName: 'banner_groups_list', routeParams: { id: this.storeFrontId } },
      { name: 'CategoryGroups', routeName: 'category_groups_list', routeParams: { id: this.storeFrontId } },
      { name: 'Sections', routeName: 'store_front_sections_list', routeParams: { id: this.storeFrontId } },
    ];
  }

  private goToSection(section: ContentSectionItem) {
    this.$router.push({ name: section.routeName, params: section.routeParams });
  }
}
